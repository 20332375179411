import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// npm install sass
// npm install react-router-dom@5
// npm i --save-dev @types/react-router-dom

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


