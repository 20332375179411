import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbedVideo1 = () => (
  <div className="video-responsive">
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/0mELerlSakg?si=I40dnOde9g8FPc8l`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default YoutubeEmbedVideo1;
