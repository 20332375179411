
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { baseUrl, debug, fetchRecords } from "../utils/AppUtil";
import avatarPlaceholder from './images/avatar-placeholder.png';
import { SpinnerLoading } from "../utils/SpinnerLoading";

type DeceasedResult = {
    deceasedId: string,
    firstName: string,
    lastName: string,
    middleName: string,
    gender: string,
    cemeteryName: string,
    birthDate: string,
    birthDateDisplay: string,
    deathDate: string,
    deathDateDisplay: string,
    yahrzeitDat: string,
    yahrzeitDateDisplay:string,
    yahrzeitDesc:string,
    profilePicture: string
}

export const SearchDeceasedRecord: React.FC = () => {

    const searchInputFirst = useRef<HTMLInputElement>(null);
    const searchInputLast = useRef<HTMLInputElement>(null);
    const [searchValueFirst, setSearchValueFirst] = useState<string>("");
    const [searchValueLast, setSearchValueLast] = useState<string>("");
    const [searchResult, setSearchResult] = useState<React.ReactElement[]>();
    const [numberOfResults, setNumberOfResults] = useState<number>(0);
    const [currentPageNo, setCurrentPageNo] = useState<number>(0);
    const pageSize: number = 30;
    let numberOfPages: number = 0;
    const [searching, setSearching] = useState(false);
    const [pagination, setPagination] = useState<React.ReactElement[]>();

    useEffect( () => {
        setSearching(true);
        fetchRecords(`deceased/searchFirstLast?firstName=${searchValueFirst}&lastName=${searchValueLast}&page=${0}&size=${pageSize}`, processData);
        setSearching(false);

    }, [searchValueFirst, searchValueLast]);

    const processData = (responseJson: any) => {

        if(responseJson?.data){
            setSearchResult(buildListJSX(responseJson.data));
        }

        if(responseJson?.numRecords) {
            setNumberOfResults(responseJson.numRecords);
        } else {
            setNumberOfResults(0);
        }

        // if(responseJson?.pageNum) {
        //     setCurrentPageNo(responseJson.pageNum);
        // };

        if(responseJson?.numPages){
            numberOfPages = responseJson.numPages;
        }

        setPagination(buildPagenationJSX(responseJson?.pageNum));
    }

    function handleSearchChangeFirst(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();
        setSearchValueFirst(event.currentTarget.value);
    }

    function handleSearchChangeLast(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();
        setSearchValueLast(event.currentTarget.value);
    }

    const buildListJSX = (data: DeceasedResult[]): React.ReactElement[] => {
        
        const result: React.ReactElement[] = [];
        let key: number = 0;
        data.forEach((deceased: DeceasedResult) => {
            
            let ageAtDeath: any = "";
            if (deceased.birthDateDisplay) {
                const birthDate = deceased.birthDateDisplay;
                const deathDate = deceased.deathDateDisplay;
    
                let birthDateYear: any = "";
                if (birthDate) {
                    birthDateYear = birthDate.slice(birthDate.length - 4);
                }
        
                let deathDateYear: any = "";
                if (deathDate) {
                    deathDateYear = deathDate.slice(deathDate.length - 4);
                }
        
                ageAtDeath = (deathDateYear - birthDateYear);
                
            } else {
                ageAtDeath = "Unavailable";
            }
    
            let imageSrc = avatarPlaceholder;
            if (deceased.profilePicture) {
                imageSrc = (baseUrl + deceased.profilePicture);
            }

            result.push(
                <li key={key} className="search-results list-group-item">
    
                    <div className="search-details d-flex justify-content-between gap-3 align-items-center">
    
                        <div className="d-flex justify-content-between align-items-center w-100 flex-wrap">
                            <a href={`/deceased/${deceased.deceasedId}`} className="d-flex gap-3 align-items-center jc-txt-blue fs-6 fw-bold">
                                <img src={imageSrc} className="recent-image" />
                                {deceased.firstName + ' ' + deceased.lastName}
                            </a>
                            
                            <p className="fs-7 fw-bold">Death Date: {deceased.deathDateDisplay ? deceased.deathDateDisplay : "Unavailable"}</p>
                        </div>
    
                        <button className="search-drop-svg" data-bs-toggle="collapse" data-bs-target={`#${deceased.deceasedId}`} aria-expanded="false" aria-controls="collapseExample">
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_3957_64331)">
                                    <rect width="20" height="20" transform="translate(0 0.00195312)" fill="white" fillOpacity="0.01"/>
                                    <path fill-rule="evenodd" clipRule="evenodd" d="M2.05666 5.80862C2.11472 5.75041 2.18369 5.70423 2.25962 5.67273C2.33555 5.64122 2.41696 5.625 2.49916 5.625C2.58137 5.625 2.66277 5.64122 2.73871 5.67273C2.81464 5.70423 2.88361 5.75041 2.94166 5.80862L9.99916 12.8674L17.0567 5.80862C17.1148 5.75051 17.1838 5.70441 17.2597 5.67296C17.3356 5.64151 17.417 5.62533 17.4992 5.62533C17.5813 5.62533 17.6627 5.64151 17.7386 5.67296C17.8146 5.70441 17.8836 5.75051 17.9417 5.80862C17.9998 5.86673 18.0459 5.93571 18.0773 6.01164C18.1088 6.08756 18.125 6.16894 18.125 6.25112C18.125 6.3333 18.1088 6.41467 18.0773 6.4906C18.0459 6.56652 17.9998 6.63551 17.9417 6.69362L10.4417 14.1936C10.3836 14.2518 10.3146 14.298 10.2387 14.3295C10.1628 14.361 10.0814 14.3772 9.99916 14.3772C9.91695 14.3772 9.83555 14.361 9.75962 14.3295C9.68369 14.298 9.61472 14.2518 9.55666 14.1936L2.05666 6.69362C1.99846 6.63556 1.95228 6.56659 1.92077 6.49066C1.88927 6.41473 1.87305 6.33333 1.87305 6.25112C1.87305 6.16891 1.88927 6.08751 1.92077 6.01157C1.95228 5.93564 1.99846 5.86667 2.05666 5.80862Z" fill="#463D3D"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_3957_64331">
                                        <rect width="20" height="20" fill="white" transform="translate(0 0.00195312)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
    
                    </div>
    
                    <div className="collapse" id={deceased.deceasedId}>
                        <div className="d-block d-sm-none">
                            <div className="d-flex gap-3 flex-wrap py-3">
                                <div className="d-flex gap-3 flex-column">
                                    <p className="fs-7"><span className="fw-bold">Gender</span><br/>{deceased.gender}</p>
                                    <p className="fs-7"><span className="fw-bold">Age at Death</span><br/>{ageAtDeath}</p>
                                </div>

                                <div className="d-flex gap-3 flex-column">
                                    <p className="fs-7"><span className="fw-bold">Date of Birth</span><br/>{deceased.birthDateDisplay ? deceased.birthDateDisplay: "Unavailable"}</p>
                                    <p className="fs-7"><span className="fw-bold">Cemetery</span><br/>{deceased.cemeteryName}</p>
                                </div>

                                <div className="d-flex gap-3 flex-column">
                                    <p className="fs-7"><span className="fw-bold">Yahrzeit</span><br/>{deceased.yahrzeitDesc ? deceased.yahrzeitDesc : "Unavailable"} {deceased.yahrzeitDateDisplay && "(" +  deceased.yahrzeitDateDisplay + ")"}</p>
                                </div>
                            </div>
                        </div>

                        <div className="d-none d-sm-block">
                            <div className="d-flex gap-5 flex-wrap py-3">
                                <p className="fs-7"><span className="fw-bold">Gender</span><br/>{deceased.gender}</p>
                                <p className="fs-7"><span className="fw-bold">Age at Death</span><br/>{ageAtDeath}</p>
                                <p className="fs-7"><span className="fw-bold">Date of Birth</span><br/>{deceased.birthDateDisplay ? deceased.birthDateDisplay: "Unavailable"}</p>
                                <p className="fs-7"><span className="fw-bold">Cemetery</span><br/>{deceased.cemeteryName}</p>
                                <p className="fs-7"><span className="fw-bold">Yahrzeit</span><br/>{deceased.yahrzeitDesc ? deceased.yahrzeitDesc : "Unavailable"} {deceased.yahrzeitDateDisplay && "(" +  deceased.yahrzeitDateDisplay + ")"}</p>
                            </div>
                        </div>
                    </div>
                </li>
            );
            key++;
        });
        return result;
    }

    const buildPagenationJSX = (pageNumber: number): React.ReactElement[] => {
        const result: React.ReactElement[] = [];

        let minPageNumber = 0;
        let maxPageNumber = pageNumber + 3;

        if (pageNumber == 0) {
            minPageNumber = 0;
        } else if (pageNumber == 1) {
            minPageNumber = pageNumber - 1;
        } else  {
            minPageNumber = pageNumber - 2;
        }

        if (pageNumber <= 2) {
            maxPageNumber = 5;
        }

        if (pageNumber == numberOfPages || (pageNumber + 1) == numberOfPages || (pageNumber + 2) == numberOfPages) {
            maxPageNumber = numberOfPages;
        }

        let initPage = minPageNumber;

        for (let num = initPage; num < maxPageNumber; num++) {

            if (numberOfPages > 1) {

                if (pageNumber === num) {
                    result.push(
                        <li key={num} className="page-item">
                            <button className="page-link jc-bg-dark" onClick={() => handlePaginationClick(num)}>
                                <p className="jc-txt-body jc-txt-light">{num + 1}</p>
                            </button>
                        </li>
                    );
                } else {
                    result.push(
                        <li key={num} className="page-item">
                            <button className="page-link" onClick={() => handlePaginationClick(num)}>
                                <p className="jc-txt-body">{num + 1}</p>
                            </button>
                        </li>
                    );
                }
            }
        }

        return result;
    }

    const handlePaginationClick = (pageNo: number) => {

        if (pageNo >= 0) {
            setCurrentPageNo(pageNo);
            setSearching(true);
            fetchRecords(`deceased/searchFirstLast?firstName=${searchValueFirst}&lastName=${searchValueLast}&page=${pageNo}&size=${pageSize}`, processData);
            setSearching(false);
        }
    }

    if(searching) {
        return(
            <SpinnerLoading />
        )
    }

    return (
        <>
            <div className="d-flex gap-3 mb-4 align-items-baseline">
                <p className="jc-txt-heading fs-3">Search Deceased</p>
                {searchValueFirst || searchValueLast ? <p className="jc-txt-body fs-6">{numberOfResults} results for <b>{searchValueFirst} {searchValueLast}</b></p> : null}
                
            </div>

            <div className="input-group mb-3">
                <input ref={searchInputFirst} id="firstName" name="firstName" value={searchValueFirst} type="text" onChange={handleSearchChangeFirst} className="form-control search-first" placeholder="First Name" />
                <input ref={searchInputLast} id="lastName" name="lastName" value={searchValueLast} type="text" onChange={handleSearchChangeLast} className="form-control search-last" placeholder="Last Name" />
            </div>

            <div className="search-results-container mb-4">
                <ul className="list-group list-group-flush jc-txt-body">
                    {searchResult}
                </ul>
            </div>

            { numberOfResults > 30 ? 
                <div className="d-flex justify-content-center w-100">
                    <nav aria-label="Page navigation example">

                        <ul className="pagination pagination-md">

                            <li className="page-item">
                                <a className="page-link" aria-label="Previous"  onClick={() => handlePaginationClick(currentPageNo - 1)} style={{borderTopLeftRadius: "99px", borderBottomLeftRadius: "99px", cursor: "pointer"}}>
                                    <a aria-hidden="true">
                                        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-4px"}}>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.42425 0.737124C5.45917 0.771958 5.48688 0.813339 5.50579 0.858898C5.52469 0.904457 5.53442 0.953298 5.53442 1.00262C5.53442 1.05195 5.52469 1.10079 5.50579 1.14635C5.48688 1.19191 5.45917 1.23329 5.42425 1.26812L1.189 5.50262L5.42425 9.73712C5.49467 9.80754 5.53422 9.90304 5.53422 10.0026C5.53422 10.1022 5.49467 10.1977 5.42425 10.2681C5.35384 10.3385 5.25833 10.3781 5.15875 10.3781C5.05917 10.3781 4.96367 10.3385 4.89325 10.2681L0.393251 5.76812C0.358329 5.73329 0.330622 5.69191 0.311717 5.64635C0.292812 5.60079 0.283081 5.55195 0.283081 5.50262C0.283081 5.4533 0.292812 5.40446 0.311717 5.3589C0.330622 5.31334 0.358329 5.27196 0.393251 5.23712L4.89325 0.737124C4.92809 0.702201 4.96947 0.674494 5.01503 0.655589C5.06059 0.636684 5.10943 0.626953 5.15875 0.626953C5.20808 0.626953 5.25692 0.636684 5.30248 0.655589C5.34803 0.674494 5.38942 0.702201 5.42425 0.737124Z" fill="#463D3D"/>
                                        </svg>
                                    </a>
                                </a>
                            </li>

                            {pagination}

                            <li className="page-item">
                                <a className="page-link" aria-label="Next"  onClick={() => handlePaginationClick(currentPageNo + 1)} style={{borderTopRightRadius: "99px", borderBottomRightRadius: "99px", cursor: "pointer"}}>
                                    <a aria-hidden="true">
                                        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginTop: "-4px"}}>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.575014 0.737124C0.609848 0.702201 0.65123 0.674494 0.696789 0.655589C0.742348 0.636684 0.791188 0.626953 0.840514 0.626953C0.889839 0.626953 0.93868 0.636684 0.984239 0.655589C1.0298 0.674494 1.07118 0.702201 1.10601 0.737124L5.60601 5.23712C5.64094 5.27196 5.66864 5.31334 5.68755 5.3589C5.70645 5.40446 5.71618 5.4533 5.71618 5.50262C5.71618 5.55195 5.70645 5.60079 5.68755 5.64635C5.66864 5.69191 5.64094 5.73329 5.60601 5.76812L1.10601 10.2681C1.0356 10.3385 0.940096 10.3781 0.840514 10.3781C0.740932 10.3781 0.645429 10.3385 0.575014 10.2681C0.504599 10.1977 0.46504 10.1022 0.46504 10.0026C0.46504 9.90304 0.504599 9.80754 0.575014 9.73712L4.81026 5.50262L0.575014 1.26812C0.540091 1.23329 0.512384 1.19191 0.493479 1.14635C0.474575 1.10079 0.464844 1.05195 0.464844 1.00262C0.464844 0.953298 0.474575 0.904457 0.493479 0.858898C0.512384 0.813339 0.540091 0.771958 0.575014 0.737124Z" fill="#463D3D"/>
                                        </svg>
                                    </a>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                : null
            }

            
        </>
    );
}












{/* <div className="collapse" id={deceased.deceasedId}>
    <div className="d-block d-sm-none">
        <div className="d-flex gap-3 flex-wrap py-3">
            <div className="d-flex gap-3 flex-column gap-1">
                <p className="fs-7"><span className="fw-bold">Gender</span><br/>{deceased.gender}</p>
                <p className="fs-7"><span className="fw-bold">Age at Death</span><br/>{ageAtDeath}</p>
            </div>

            <div className="d-flex gap-3 flex-column gap-1">
                <p className="fs-7"><span className="fw-bold">Date of Birth</span><br/>{deceased.birthDateDisplay ? deceased.birthDateDisplay: "Unavailable"}</p>
                <p className="fs-7"><span className="fw-bold">Cemetery</span><br/>{deceased.cemeteryName}</p>
            </div>

            <div className="d-flex gap-3 flex-column gap-1">
                <p className="fs-7"><span className="fw-bold">Yahrzeit</span><br/>{deceased.yahrzeitDesc ? deceased.yahrzeitDesc : "Unavailable"} {deceased.yahrzeitDateDisplay && "(" +  deceased.yahrzeitDateDisplay + ")"}</p>
            </div>
        </div>
    </div>

    <div className="d-none d-sm-block">
        <div className="d-flex gap-3 flex-wrap py-3">
            <p className="fs-7"><span className="fw-bold">Gender</span><br/>{deceased.gender}</p>
            <p className="fs-7"><span className="fw-bold">Age at Death</span><br/>{ageAtDeath}</p>
            <p className="fs-7"><span className="fw-bold">Date of Birth</span><br/>{deceased.birthDateDisplay ? deceased.birthDateDisplay: "Unavailable"}</p>
            <p className="fs-7"><span className="fw-bold">Cemetery</span><br/>{deceased.cemeteryName}</p>
            <p className="fs-7"><span className="fw-bold">Yahrzeit</span><br/>{deceased.yahrzeitDesc ? deceased.yahrzeitDesc : "Unavailable"} {deceased.yahrzeitDateDisplay && "(" +  deceased.yahrzeitDateDisplay + ")"}</p>
        </div>
    </div>
</div> */}